<template>
  <svg
    width="67"
    height="65"
    viewBox="0 0 67 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.46775 5H58.5421C61.5483 5 64.0049 7.34247 64.0049 10.2089V14H1.00494V10.2089C1.00494 7.34247 3.46159 5 6.46775 5Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M7.24728 5H58.3443C62.0125 5 65.0049 7.95562 65.0049 11.5786V56.7074C65.0049 60.1715 62.1412 63 58.6339 63H7.34381C3.83652 63 1.00494 60.2033 1.00494 56.7392V11.1655C1.00494 7.76493 3.80434 5.03178 7.2151 5.03178L7.24728 5Z"
      stroke="var(--v-illustrations-darken1)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.6049 20H23.4049C22.6317 20 22.0049 20.6268 22.0049 21.4V24.6C22.0049 25.3732 22.6317 26 23.4049 26H26.6049C27.3781 26 28.0049 25.3732 28.0049 24.6V21.4C28.0049 20.6268 27.3781 20 26.6049 20Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M26.6049 36H23.4049C22.6317 36 22.0049 36.6268 22.0049 37.4V40.6C22.0049 41.3732 22.6317 42 23.4049 42H26.6049C27.3781 42 28.0049 41.3732 28.0049 40.6V37.4C28.0049 36.6268 27.3781 36 26.6049 36Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M26.6049 44H23.4049C22.6317 44 22.0049 44.6268 22.0049 45.4V48.6C22.0049 49.3732 22.6317 50 23.4049 50H26.6049C27.3781 50 28.0049 49.3732 28.0049 48.6V45.4C28.0049 44.6268 27.3781 44 26.6049 44Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M26.6049 52H23.4049C22.6317 52 22.0049 52.6268 22.0049 53.4V56.6C22.0049 57.3732 22.6317 58 23.4049 58H26.6049C27.3781 58 28.0049 57.3732 28.0049 56.6V53.4C28.0049 52.6268 27.3781 52 26.6049 52Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M26.6049 28H23.4049C22.6317 28 22.0049 28.6268 22.0049 29.4V32.6C22.0049 33.3732 22.6317 34 23.4049 34H26.6049C27.3781 34 28.0049 33.3732 28.0049 32.6V29.4C28.0049 28.6268 27.3781 28 26.6049 28Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M42.6049 20H39.4049C38.6317 20 38.0049 20.6268 38.0049 21.4V24.6C38.0049 25.3732 38.6317 26 39.4049 26H42.6049C43.3781 26 44.0049 25.3732 44.0049 24.6V21.4C44.0049 20.6268 43.3781 20 42.6049 20Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M42.6049 36H39.4049C38.6317 36 38.0049 36.6268 38.0049 37.4V40.6C38.0049 41.3732 38.6317 42 39.4049 42H42.6049C43.3781 42 44.0049 41.3732 44.0049 40.6V37.4C44.0049 36.6268 43.3781 36 42.6049 36Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M42.6049 44H39.4049C38.6317 44 38.0049 44.6268 38.0049 45.4V48.6C38.0049 49.3732 38.6317 50 39.4049 50H42.6049C43.3781 50 44.0049 49.3732 44.0049 48.6V45.4C44.0049 44.6268 43.3781 44 42.6049 44Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M42.6049 28H39.4049C38.6317 28 38.0049 28.6268 38.0049 29.4V32.6C38.0049 33.3732 38.6317 34 39.4049 34H42.6049C43.3781 34 44.0049 33.3732 44.0049 32.6V29.4C44.0049 28.6268 43.3781 28 42.6049 28Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M50.6049 20H47.4049C46.6317 20 46.0049 20.6268 46.0049 21.4V24.6C46.0049 25.3732 46.6317 26 47.4049 26H50.6049C51.3781 26 52.0049 25.3732 52.0049 24.6V21.4C52.0049 20.6268 51.3781 20 50.6049 20Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M50.6049 36H47.4049C46.6317 36 46.0049 36.6268 46.0049 37.4V40.6C46.0049 41.3732 46.6317 42 47.4049 42H50.6049C51.3781 42 52.0049 41.3732 52.0049 40.6V37.4C52.0049 36.6268 51.3781 36 50.6049 36Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M50.6049 44H47.4049C46.6317 44 46.0049 44.6268 46.0049 45.4V48.6C46.0049 49.3732 46.6317 50 47.4049 50H50.6049C51.3781 50 52.0049 49.3732 52.0049 48.6V45.4C52.0049 44.6268 51.3781 44 50.6049 44Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M50.6049 28H47.4049C46.6317 28 46.0049 28.6268 46.0049 29.4V32.6C46.0049 33.3732 46.6317 34 47.4049 34H50.6049C51.3781 34 52.0049 33.3732 52.0049 32.6V29.4C52.0049 28.6268 51.3781 28 50.6049 28Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M58.6049 20H55.4049C54.6317 20 54.0049 20.6268 54.0049 21.4V24.6C54.0049 25.3732 54.6317 26 55.4049 26H58.6049C59.3781 26 60.0049 25.3732 60.0049 24.6V21.4C60.0049 20.6268 59.3781 20 58.6049 20Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M58.6049 36H55.4049C54.6317 36 54.0049 36.6268 54.0049 37.4V40.6C54.0049 41.3732 54.6317 42 55.4049 42H58.6049C59.3781 42 60.0049 41.3732 60.0049 40.6V37.4C60.0049 36.6268 59.3781 36 58.6049 36Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M58.6049 44H55.4049C54.6317 44 54.0049 44.6268 54.0049 45.4V48.6C54.0049 49.3732 54.6317 50 55.4049 50H58.6049C59.3781 50 60.0049 49.3732 60.0049 48.6V45.4C60.0049 44.6268 59.3781 44 58.6049 44Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M58.6049 28H55.4049C54.6317 28 54.0049 28.6268 54.0049 29.4V32.6C54.0049 33.3732 54.6317 34 55.4049 34H58.6049C59.3781 34 60.0049 33.3732 60.0049 32.6V29.4C60.0049 28.6268 59.3781 28 58.6049 28Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M34.6049 20H31.4049C30.6317 20 30.0049 20.6268 30.0049 21.4V24.6C30.0049 25.3732 30.6317 26 31.4049 26H34.6049C35.3781 26 36.0049 25.3732 36.0049 24.6V21.4C36.0049 20.6268 35.3781 20 34.6049 20Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M34.6049 36H31.4049C30.6317 36 30.0049 36.6268 30.0049 37.4V40.6C30.0049 41.3732 30.6317 42 31.4049 42H34.6049C35.3781 42 36.0049 41.3732 36.0049 40.6V37.4C36.0049 36.6268 35.3781 36 34.6049 36Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M34.6049 44H31.4049C30.6317 44 30.0049 44.6268 30.0049 45.4V48.6C30.0049 49.3732 30.6317 50 31.4049 50H34.6049C35.3781 50 36.0049 49.3732 36.0049 48.6V45.4C36.0049 44.6268 35.3781 44 34.6049 44Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M34.6049 52H31.4049C30.6317 52 30.0049 52.6268 30.0049 53.4V56.6C30.0049 57.3732 30.6317 58 31.4049 58H34.6049C35.3781 58 36.0049 57.3732 36.0049 56.6V53.4C36.0049 52.6268 35.3781 52 34.6049 52Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M34.6049 28H31.4049C30.6317 28 30.0049 28.6268 30.0049 29.4V32.6C30.0049 33.3732 30.6317 34 31.4049 34H34.6049C35.3781 34 36.0049 33.3732 36.0049 32.6V29.4C36.0049 28.6268 35.3781 28 34.6049 28Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M18.6049 36H15.4049C14.6317 36 14.0049 36.6268 14.0049 37.4V40.6C14.0049 41.3732 14.6317 42 15.4049 42H18.6049C19.3781 42 20.0049 41.3732 20.0049 40.6V37.4C20.0049 36.6268 19.3781 36 18.6049 36Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M18.6049 44H15.4049C14.6317 44 14.0049 44.6268 14.0049 45.4V48.6C14.0049 49.3732 14.6317 50 15.4049 50H18.6049C19.3781 50 20.0049 49.3732 20.0049 48.6V45.4C20.0049 44.6268 19.3781 44 18.6049 44Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M18.6049 52H15.4049C14.6317 52 14.0049 52.6268 14.0049 53.4V56.6C14.0049 57.3732 14.6317 58 15.4049 58H18.6049C19.3781 58 20.0049 57.3732 20.0049 56.6V53.4C20.0049 52.6268 19.3781 52 18.6049 52Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M18.6049 28H15.4049C14.6317 28 14.0049 28.6268 14.0049 29.4V32.6C14.0049 33.3732 14.6317 34 15.4049 34H18.6049C19.3781 34 20.0049 33.3732 20.0049 32.6V29.4C20.0049 28.6268 19.3781 28 18.6049 28Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M10.6049 36H7.40494C6.63175 36 6.00494 36.6268 6.00494 37.4V40.6C6.00494 41.3732 6.63175 42 7.40494 42H10.6049C11.3781 42 12.0049 41.3732 12.0049 40.6V37.4C12.0049 36.6268 11.3781 36 10.6049 36Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M10.6049 44H7.40494C6.63175 44 6.00494 44.6268 6.00494 45.4V48.6C6.00494 49.3732 6.63175 50 7.40494 50H10.6049C11.3781 50 12.0049 49.3732 12.0049 48.6V45.4C12.0049 44.6268 11.3781 44 10.6049 44Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M10.6049 52H7.40494C6.63175 52 6.00494 52.6268 6.00494 53.4V56.6C6.00494 57.3732 6.63175 58 7.40494 58H10.6049C11.3781 58 12.0049 57.3732 12.0049 56.6V53.4C12.0049 52.6268 11.3781 52 10.6049 52Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M10.6049 28H7.40494C6.63175 28 6.00494 28.6268 6.00494 29.4V32.6C6.00494 33.3732 6.63175 34 7.40494 34H10.6049C11.3781 34 12.0049 33.3732 12.0049 32.6V29.4C12.0049 28.6268 11.3781 28 10.6049 28Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M1.00494 15L65.0049 15"
      stroke="var(--v-illustrations-darken1)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0049 1L13.0063 9"
      stroke="#797997"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M53.0049 1L53.0049 9"
      stroke="#797997"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>