<template>
  <svg
    width="67"
    height="64"
    viewBox="0 0 67 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.4628 5H58.5372C61.5434 5 64 7.34247 64 10.2089V14H1V10.2089C1 7.34247 3.45664 5 6.4628 5Z"
      fill="var(--v-illustrations-lighten1)"
    />
    <path
      d="M7.24233 5H58.3394C62.0075 5 65 7.95562 65 11.5786V56.7074C65 60.1715 62.1362 63 58.629 63H7.33886C3.83157 63 1 60.2033 1 56.7392V11.1655C1 7.76493 3.7994 5.03178 7.21016 5.03178L7.24233 5Z"
      stroke="var(--v-illustrations-darken1)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.6 20H23.4C22.6268 20 22 20.6268 22 21.4V24.6C22 25.3732 22.6268 26 23.4 26H26.6C27.3732 26 28 25.3732 28 24.6V21.4C28 20.6268 27.3732 20 26.6 20Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M26.6 36H23.4C22.6268 36 22 36.6268 22 37.4V40.6C22 41.3732 22.6268 42 23.4 42H26.6C27.3732 42 28 41.3732 28 40.6V37.4C28 36.6268 27.3732 36 26.6 36Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M26.6 44H23.4C22.6268 44 22 44.6268 22 45.4V48.6C22 49.3732 22.6268 50 23.4 50H26.6C27.3732 50 28 49.3732 28 48.6V45.4C28 44.6268 27.3732 44 26.6 44Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M26.6 52H23.4C22.6268 52 22 52.6268 22 53.4V56.6C22 57.3732 22.6268 58 23.4 58H26.6C27.3732 58 28 57.3732 28 56.6V53.4C28 52.6268 27.3732 52 26.6 52Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M26.6 28H23.4C22.6268 28 22 28.6268 22 29.4V32.6C22 33.3732 22.6268 34 23.4 34H26.6C27.3732 34 28 33.3732 28 32.6V29.4C28 28.6268 27.3732 28 26.6 28Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M42.6 20H39.4C38.6268 20 38 20.6268 38 21.4V24.6C38 25.3732 38.6268 26 39.4 26H42.6C43.3732 26 44 25.3732 44 24.6V21.4C44 20.6268 43.3732 20 42.6 20Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M42.6 36H39.4C38.6268 36 38 36.6268 38 37.4V40.6C38 41.3732 38.6268 42 39.4 42H42.6C43.3732 42 44 41.3732 44 40.6V37.4C44 36.6268 43.3732 36 42.6 36Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M42.6 44H39.4C38.6268 44 38 44.6268 38 45.4V48.6C38 49.3732 38.6268 50 39.4 50H42.6C43.3732 50 44 49.3732 44 48.6V45.4C44 44.6268 43.3732 44 42.6 44Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M42.6 28H39.4C38.6268 28 38 28.6268 38 29.4V32.6C38 33.3732 38.6268 34 39.4 34H42.6C43.3732 34 44 33.3732 44 32.6V29.4C44 28.6268 43.3732 28 42.6 28Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M50.6 20H47.4C46.6268 20 46 20.6268 46 21.4V24.6C46 25.3732 46.6268 26 47.4 26H50.6C51.3732 26 52 25.3732 52 24.6V21.4C52 20.6268 51.3732 20 50.6 20Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M50.6 36H47.4C46.6268 36 46 36.6268 46 37.4V40.6C46 41.3732 46.6268 42 47.4 42H50.6C51.3732 42 52 41.3732 52 40.6V37.4C52 36.6268 51.3732 36 50.6 36Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M50.6 44H47.4C46.6268 44 46 44.6268 46 45.4V48.6C46 49.3732 46.6268 50 47.4 50H50.6C51.3732 50 52 49.3732 52 48.6V45.4C52 44.6268 51.3732 44 50.6 44Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M50.6 28H47.4C46.6268 28 46 28.6268 46 29.4V32.6C46 33.3732 46.6268 34 47.4 34H50.6C51.3732 34 52 33.3732 52 32.6V29.4C52 28.6268 51.3732 28 50.6 28Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M58.6 20H55.4C54.6268 20 54 20.6268 54 21.4V24.6C54 25.3732 54.6268 26 55.4 26H58.6C59.3732 26 60 25.3732 60 24.6V21.4C60 20.6268 59.3732 20 58.6 20Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M58.6 36H55.4C54.6268 36 54 36.6268 54 37.4V40.6C54 41.3732 54.6268 42 55.4 42H58.6C59.3732 42 60 41.3732 60 40.6V37.4C60 36.6268 59.3732 36 58.6 36Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M58.6 44H55.4C54.6268 44 54 44.6268 54 45.4V48.6C54 49.3732 54.6268 50 55.4 50H58.6C59.3732 50 60 49.3732 60 48.6V45.4C60 44.6268 59.3732 44 58.6 44Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M58.6 28H55.4C54.6268 28 54 28.6268 54 29.4V32.6C54 33.3732 54.6268 34 55.4 34H58.6C59.3732 34 60 33.3732 60 32.6V29.4C60 28.6268 59.3732 28 58.6 28Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M34.6 20H31.4C30.6268 20 30 20.6268 30 21.4V24.6C30 25.3732 30.6268 26 31.4 26H34.6C35.3732 26 36 25.3732 36 24.6V21.4C36 20.6268 35.3732 20 34.6 20Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M34.6 36H31.4C30.6268 36 30 36.6268 30 37.4V40.6C30 41.3732 30.6268 42 31.4 42H34.6C35.3732 42 36 41.3732 36 40.6V37.4C36 36.6268 35.3732 36 34.6 36Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M34.6 44H31.4C30.6268 44 30 44.6268 30 45.4V48.6C30 49.3732 30.6268 50 31.4 50H34.6C35.3732 50 36 49.3732 36 48.6V45.4C36 44.6268 35.3732 44 34.6 44Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M34.6 52H31.4C30.6268 52 30 52.6268 30 53.4V56.6C30 57.3732 30.6268 58 31.4 58H34.6C35.3732 58 36 57.3732 36 56.6V53.4C36 52.6268 35.3732 52 34.6 52Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M34.6 28H31.4C30.6268 28 30 28.6268 30 29.4V32.6C30 33.3732 30.6268 34 31.4 34H34.6C35.3732 34 36 33.3732 36 32.6V29.4C36 28.6268 35.3732 28 34.6 28Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M18.6 36H15.4C14.6268 36 14 36.6268 14 37.4V40.6C14 41.3732 14.6268 42 15.4 42H18.6C19.3732 42 20 41.3732 20 40.6V37.4C20 36.6268 19.3732 36 18.6 36Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M18.6 44H15.4C14.6268 44 14 44.6268 14 45.4V48.6C14 49.3732 14.6268 50 15.4 50H18.6C19.3732 50 20 49.3732 20 48.6V45.4C20 44.6268 19.3732 44 18.6 44Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M18.6 52H15.4C14.6268 52 14 52.6268 14 53.4V56.6C14 57.3732 14.6268 58 15.4 58H18.6C19.3732 58 20 57.3732 20 56.6V53.4C20 52.6268 19.3732 52 18.6 52Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M18.6 28H15.4C14.6268 28 14 28.6268 14 29.4V32.6C14 33.3732 14.6268 34 15.4 34H18.6C19.3732 34 20 33.3732 20 32.6V29.4C20 28.6268 19.3732 28 18.6 28Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M10.6 36H7.4C6.6268 36 6 36.6268 6 37.4V40.6C6 41.3732 6.6268 42 7.4 42H10.6C11.3732 42 12 41.3732 12 40.6V37.4C12 36.6268 11.3732 36 10.6 36Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M10.6 44H7.4C6.6268 44 6 44.6268 6 45.4V48.6C6 49.3732 6.6268 50 7.4 50H10.6C11.3732 50 12 49.3732 12 48.6V45.4C12 44.6268 11.3732 44 10.6 44Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M10.6 52H7.4C6.6268 52 6 52.6268 6 53.4V56.6C6 57.3732 6.6268 58 7.4 58H10.6C11.3732 58 12 57.3732 12 56.6V53.4C12 52.6268 11.3732 52 10.6 52Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M10.6 28H7.4C6.6268 28 6 28.6268 6 29.4V32.6C6 33.3732 6.6268 34 7.4 34H10.6C11.3732 34 12 33.3732 12 32.6V29.4C12 28.6268 11.3732 28 10.6 28Z"
      fill="var(--v-primary-base)"
    />
    <path
      d="M1 15L65 15"
      stroke="var(--v-illustrations-darken1)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13 1V9"
      stroke="#797997"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M53 1L53 9"
      stroke="#797997"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>